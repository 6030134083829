import React from "react";
import Typography from "../common/Typography/Typography";
import "./Contact.scss";
import ButtonMailto from "../common/ButtonMailto/ButtonMailto";

const Contact = ({ onClick }) => {
  return (
    <>
      <section id="contact" className="contact-f__wrapper">
        <div className="container contact-f__container">
          <Typography variant={"h4"}>Let's work together</Typography>
          <ButtonMailto />
        </div>
      </section>
    </>
  );
};

export default Contact;
