import { useState } from "react";
import NavLogo from "./../NavLogo/NavLogo";
import "./NavigationMenu.scss";
import logo from "../../img/logo-white.svg";
import { Link } from "react-router-dom";

const NavigationMenu = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <>
      <section className=" navigation__wrapper">
        <div className=" container navigation__container">
          <NavLogo logo={logo} />
          <nav className="nav__menu">
            <li>
              <Link
                to={"/"}
                onClick={() => setActiveNav("/")}
                className={activeNav === "/" ? "active" : ""}
              >
                Work
              </Link>
            </li>
            <li>
              <Link
                to={"/about"}
                onClick={() => setActiveNav("/about")}
                className={activeNav === "/about" ? "active" : ""}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to={"/contact"}
                onClick={() => setActiveNav("/contact")}
                className={activeNav === "/contact" ? "active" : ""}
              >
                Contact
              </Link>
            </li>
          </nav>
        </div>
      </section>
    </>
  );
};

export default NavigationMenu;
