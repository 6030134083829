import React from "react";
import Link from "../Link/Link";
import "./ButtonMailto.scss";

const ButtonMailto = () => {
  return (
    <>
      <div className="button__wrapper">
        <Link href="mailto:hi@makintami.dev" className="btn disabled">
          hi@makintami.dev
        </Link>
      </div>
    </>
  );
};

export default ButtonMailto;
