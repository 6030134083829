import React from "react";
import Link from "../common/Link/Link";
import Image from "../common/Image/Image";
const NavLogo = ({ logo }) => {
  return (
    <div className="logo">
      <Link href="/">
        <Image src={logo} alt="logo" />
      </Link>
    </div>
  );
};

export default NavLogo;
