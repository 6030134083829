import React from "react";
import Link from "../common/Link/Link";

const FooterLinks = () => {
  return (
    <div className="footer__links">
      <Link href="https://www.instagram.com/makintamidesign/" target="_blank">
        Instagram
      </Link>
      <Link href="https://dribbble.com/MK__Dev" target="_blank">
        Dribbble
      </Link>
      <Link
        href="https://www.linkedin.com/in/abubakar-makintami-80b91769"
        target="_blank"
      >
        LinkedIn
      </Link>
      <Link href="https://github.com/makintamijr" target="_blank">
        Github
      </Link>
    </div>
  );
};

export default FooterLinks;
