import React, { Suspense } from "react";
import "./styles/App.scss";
import NavigationMenu from "./components/NavigationMenu/NavigationMenu";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";

const AnimatedRoutes = React.lazy(async () => {
  const [modulesExports] = await Promise.all([
    import("./AnimatedRoutes"),
    new Promise((resolve) => setTimeout(resolve, 3000)),
  ]);
  return modulesExports;
});

function App() {
  return (
    <>
      <div className="App">
        <NavigationMenu />

        <Suspense fallback={<Loader />}>
          <AnimatedRoutes />
        </Suspense>

        <Footer />
        {/* <Loader /> */}
      </div>
    </>
  );
}

export default App;
