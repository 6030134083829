import React from "react";
import "./Loader.scss";
import { motion, AnimatePresence } from "framer-motion";

const icon = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
  },
};

const bounce = {
  y: {
    duration: 0.5,
    delay: 0.5,
    yoyo: "Infinity",
    ease: "easeOut",
    type: "tween",
  },
};

const Loader = () => {
  return (
    <>
      <AnimatePresence initial={true} exitBeforeEnter={false}>
        <motion.div
          className="loader__container"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{
            y: "100vh",
            opacity: 0,
            transition: {
              type: "tween",
              duration: 0.5,
            },
          }}
        >
          <div className="loader">
            <motion.svg
              width="329"
              height="308"
              viewBox="0 0 329 308"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              animate={{ y: ["20%", "-20%"] }}
              transition={bounce}
            >
              <g clip-path="url(#clip0_264_243)">
                <motion.path
                  d="M198.497 91.3262L197.432 91.144L196.696 91.935L138.536 154.427C133.889 159.188 128.039 161.736 121.805 161.736C116.443 161.736 110.994 159.624 106.312 155.706L58.1735 110.958L57.2968 110.143L56.1644 110.531C52.3458 111.837 48.4896 112.289 44.086 112.289H44.0666L44.0472 112.29C21.2529 112.731 2 92.3604 2 66.7082C2 41.0948 21.2267 20.6607 44.995 20.6607C68.7632 20.6607 87.9899 41.0948 87.9899 66.7082C87.9899 69.0709 87.9839 71.2107 87.5724 73.3228L87.3608 74.4084L88.1678 75.1647L119.528 104.553L120.997 105.929L122.364 104.452L165.086 58.2702L165.73 57.5748L165.599 56.6364C165.141 53.3468 164.709 50.2194 164.709 47.116C164.709 21.9534 183.496 2.00146 206.341 2.00146C229.653 2.00146 248.426 21.9674 248.426 47.116C248.426 72.2646 229.653 92.2305 206.341 92.2305C203.812 92.2305 201.254 91.7978 198.497 91.3262Z"
                  stroke="white"
                  stroke-width="4"
                  variants={icon}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    duration: 0.5,
                    type: "tween",
                    ease: "easeInOut",
                  }}
                />
                <path
                  d="M78.173 260.761C78.173 234.638 97.7162 213.646 122.259 213.646C124.986 213.646 127.713 214.113 130.44 214.579L188.615 151.604C193.615 146.473 199.978 143.674 206.795 143.674C212.703 143.674 218.612 146.007 223.611 150.205L271.788 194.987C275.878 193.588 279.969 193.121 284.514 193.121C309.511 193.121 329.509 214.579 329.509 241.169C329.509 267.758 309.511 289.216 284.514 289.216C259.516 289.216 239.519 267.758 239.519 241.169C239.519 238.836 239.519 236.504 239.973 234.171L208.613 204.783L165.89 250.965C166.345 254.23 166.799 257.495 166.799 260.761C166.799 286.884 147.256 307.875 122.713 307.875C97.7162 307.875 78.173 286.884 78.173 260.761Z"
                  fill="#1762CE"
                />
              </g>
              <defs>
                <clipPath id="clip0_264_243">
                  <rect width="328.6" height="307.877" fill="white" />
                </clipPath>
              </defs>
            </motion.svg>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default Loader;
