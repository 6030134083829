import React from "react";
import "./Footer.scss";
import Contact from "../Contact/Contact";
import FooterLinks from "./../FooterLinks/FooterLinks";

const Footer = () => {
  return (
    <>
      <section className="footer__wrapper">
        <Contact />
        <div className="container footer__container">
          <div className="copyright">
            <span>&copy;2022.makintami.dev</span>
          </div>
          <FooterLinks />
        </div>
      </section>
    </>
  );
};

export default Footer;
